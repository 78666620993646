import { deleteGoal, updateGoal } from "../features/goals/goalSlice";
import { createMessage } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getSongs, reset } from "../features/songs/songSlice";
import SongItem from "./SongItem";
import GoalForm from "./GoalForm";
import GoalEditForm from "./GoalEditForm";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaExpandAlt, FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { set } from "mongoose";
import SongEditForm from "./SongEditForm";

function GoalItem({ goal, songs }) {
  const [status, setStatus] = useState("");

  const [currentStatus, setCurrentStatus] = useState(goal.status);

  const [showSongs, setShowSongs] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [showArtists, setShowArtists] = useState(false);

  const [rejectionReason, setRejectionReason] = useState("");

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  // const { songs, isLoading, isError, message } = useSelector(
  //   (state) => state.songs
  // );

  const [filteredSongs, setFilteredSongs] = useState([]);

  useEffect(() => {
    const filteredSongs = songs?.filter(
      (song) => song.albumId === goal.albumId
    );
    filteredSongs?.sort(
      (a, b) => parseInt(a.songNumber, 10) - parseInt(b.songNumber, 10)
    );
    setFilteredSongs(filteredSongs);
  }, [songs]);

  // const filteredSongs = songs?.filter((song) => song.albumId === goal.albumId);
  // filteredSongs.sort(
  //   (a, b) => parseInt(a.songNumber, 10) - parseInt(b.songNumber, 10)
  // );

  const handleDownload = async () => {
    const imageU = goal.imageUrl; // Replace with your actual image URL

    const fileUrls = filteredSongs.map((song) => song.audioUrl);
    let trackNames = "";
    for (let i = 0; i < filteredSongs.length; i++) {
      trackNames += filteredSongs[i].name;
      trackNames += ",";
    }
    trackNames += "END";
    //const fileUrls = [];
    fileUrls.push(imageU);
    console.log(fileUrls);

    let trackArtists = "";
    for (let i = 0; i < filteredSongs.length; i++) {
      let artistsStr = "";
      for (let j = 0; j < filteredSongs[i].artists.length; j++) {
        let flag = false;
        for (let k = 0; k < goal.artists.length; k++) {
          if (
            filteredSongs[i].artists[j].artistName == goal.artists[k].artistName
          ) {
            flag = true;
          }
        }
        if (flag == false || flag == true) {
          artistsStr += filteredSongs[i].artists[j].artistName;
          artistsStr += "_";
        }
      }
      if (artistsStr == "") {
        trackArtists += "none,";
      } else {
        trackArtists += artistsStr;
        trackArtists += ",";
      }
    }
    trackArtists += "END";

    toast("Download in progress. This may take up to a few minutes.");

    try {
      const response = await fetch(
        `/download?fileUrls=${JSON.stringify(
          fileUrls
        )}&trackNames=${encodeURIComponent(
          trackNames
        )}&albumName=${encodeURIComponent(
          goal.text
        )}&trackArtists=${encodeURIComponent(trackArtists)}`
      );
      const blob = await response.blob();

      // Create a download link and trigger the file download
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      // downloadLink.download = `${goal.text}_files.zip`;
      // set the file name to the goal.artists names, separated by commas with an & for the last one, followed by - and the album name
      downloadLink.download = `${goal.artists
        .map((artist) => artist.artistName)
        .join(", ")
        .replace(/, ([^,]*)$/, " & $1")} - ${goal.text}.zip`;

      downloadLink.click();
    } catch (error) {
      console.error(error);
      // Handle error if download fails
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    /*dispatch(
      updateGoal(
        {
          status: status,
        },
        goal._id
      )
    );*/
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.put("/api/goals/" + goal._id, { status: status }, config);
    /*const userId = goal.user.id;
    const email = goal.email;
    const name = goal.name;
    const message = status;*/
    /*dispatch(
      createMessage({
        userId,
        email,
        name,
        message,
      })
    );*/
    const messageData = {
      userId: goal.user, // the ID of the user that the message is for
      email: "temp",
      name: "temp",
      messageStr: status,
      album: goal.text,
    };
    axios.post("/api/messages/", messageData, config);

    axios
      .post("/send-email", {
        to: goal.accountEmail,
        subject: `A status update is available for your album ${goal.text}`,
        text: `A status update is available for your album ${goal.text}: ${status}`,
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.error(err));
    // window.location.reload(false);
    setCurrentStatus(status);
    goal.status = status;
  };

  // useEffect(() => {
  //   dispatch(getSongs());

  //   return () => {
  //     dispatch(reset());
  //   };
  // }, [user]);

  return (
    <div className="goal">
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {/*<div>{new Date(goal.createdAt).toLocaleString("en-US")}</div>*/}
        <div>
          <h2>Album Title: {goal.text}</h2>
          {user && user.role == "admin" && (
            <>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Username:</b>
                <br /> {goal.accountName}
              </h4>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Email:</b>
                <br /> {goal.accountEmail}
              </h4>
            </>
          )}
          <Popup
            modal
            nested
            trigger={
              <button
                className="close2"
                type="button"
                style={{
                  fontSize: "30px",
                  background: "none",
                  //border: "solid 1px",
                  //margin: "5px 5px 5px 5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
              >
                <FaEdit />
              </button>
            }
            {...{ contentStyle: { width: "1200px" } }}
            position="right center"
            //className="my-popup"
          >
            <div className="">
              <p>Edit Album</p>
              <GoalEditForm goal={goal} />
            </div>
          </Popup>
          <img
            src={goal.imageUrl}
            style={{
              width: "200px",
              border: "solid 3px black",
              borderRadius: "5px",
            }}
          />
          <br />
          <button
            onClick={() => {
              if (showArtists) {
                setShowArtists(false);
              } else {
                setShowArtists(true);
              }
            }}
          >
            Show Artists
          </button>
          {showArtists && (
            <>
              {" "}
              <h3>Album Artists: </h3>
              {goal.artists &&
                goal.artists[0] &&
                goal.artists.map((art) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h3 key={art.artistName}>{art.artistName} </h3>
                    <Popup
                      trigger={
                        <button
                          type="button"
                          style={{
                            fontSize: "15px",
                            background: "none",
                            border: "solid 1px",
                            margin: "5px 5px 5px 5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                          }}
                        >
                          Show Artist Details
                          <FaExpandAlt />
                        </button>
                      }
                      {...{ contentStyle: { width: "500px" } }}
                      position="bottom center"
                      //className="my-popup"
                    >
                      <div
                        className=""
                        style={{
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          wordBreak: "break-all",
                          // width: "180px",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Name:
                          </span>{" "}
                          {art.artistName}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Role:
                          </span>{" "}
                          {art.role}
                        </p>
                        <p>
                          <span
                            style={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Spotify URI:
                          </span>{" "}
                          <a
                            href={
                              //if art.spotifyURI starts with http, then use it, other, if it starts with spotify:artist, the use https://open.spotify.com/artist/ + art.spotifyURI.split(":")[2] + ?
                              art.spotifyURI.startsWith("http")
                                ? art.spotifyURI
                                : art.spotifyURI.startsWith("spotify:artist")
                                ? "https://open.spotify.com/artist/" +
                                  art.spotifyURI.split(":")[2] +
                                  "?si="
                                : "https://open.spotify.com/artist/" +
                                  art.spotifyURI +
                                  "?si="
                            }
                            target="_blank"
                            style={{
                              //make it look like a link
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {art.spotifyURI}
                          </a>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Apple ID:</span>{" "}
                          <a
                            href={
                              art.appleId.startsWith("http")
                                ? art.appleId
                                : "https://music.apple.com/us/artist/" +
                                  art.appleId
                            }
                            style={{
                              //make it look like a link
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            target="_blank"
                          >
                            {art.appleId}
                          </a>
                        </p>
                      </div>
                    </Popup>
                  </div>
                ))}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showMetadata) {
                setShowMetadata(false);
              } else {
                setShowMetadata(true);
              }
            }}
          >
            Show Metadata
          </button>
          {showMetadata && (
            <>
              <h3
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                Writers:
              </h3>
              {goal.writers.length == 0 && (
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  None
                </p>
              )}
              {goal.writers &&
                goal.writers[0] &&
                goal.writers.map((writer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                      }}
                      key={writer.writerName}
                    >
                      {writer.writerName}{" "}
                      {writer.legalName && `(${writer.legalName})`}
                    </p>
                  </div>
                ))}
              <h3
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                Producers:
              </h3>
              {goal.producers.length == 0 && (
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  None
                </p>
              )}
              {goal.producers &&
                goal.producers[0] &&
                goal.producers.map((producer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontSize: "15px" }}>{producer.producerName} </p>
                  </div>
                ))}
              {user && user.role && (
                <>
                  <h4>
                    Release Date:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.releaseDate.toString().substr(0, 10)}
                    </span>
                  </h4>
                  <h4>
                    Live Date:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.liveDate.substr(0, 10)}
                    </span>
                  </h4>
                  <h4>
                    © Line:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.cLine}
                    </span>
                  </h4>
                  <h4>
                    ℗ Line:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.pLine}
                    </span>
                  </h4>
                  <h4>
                    Label Name:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.labelName}
                    </span>
                  </h4>
                  <h4>
                    UPC:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.upc}
                    </span>
                  </h4>
                  <h4>
                    Primary Genre:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.primaryGenre}
                    </span>
                  </h4>
                  <h4>
                    Secondary Genre:{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      {goal.secondaryGenre}
                    </span>
                  </h4>
                </>
              )}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showSongs) {
                setShowSongs(false);
              } else {
                setShowSongs(true);
              }
            }}
          >
            Show Tracklist
          </button>
          {showSongs && (
            <>
              {/* <h3>Songs: </h3> */}
              <div className="songs">
                {filteredSongs?.map((song) => (
                  // <SongItem key={song._id} song={song} goal={goal} />
                  <div>
                    <h2>
                      Song {song.songNumber}:{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                        }}
                      >
                        "{song.name}"
                      </span>
                    </h2>
                    <Popup
                      modal
                      nested
                      trigger={
                        <button
                          type="button"
                          style={{
                            fontSize: "15px",
                            background: "none",
                            border: "solid 1px",
                            margin: "5px 5px 5px 5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                          }}
                        >
                          Edit Song
                          <FaEdit />
                        </button>
                      }
                      // {...{ contentStyle: { width: "800px", marginBottom: "200px" } }}
                      position="right center"
                      //className="my-popup"
                    >
                      <div className="">
                        <p>Edit Song</p>
                        <SongEditForm song={song} goal={goal} />
                      </div>
                    </Popup>
                    <h4>
                      ISRC:{" "}
                      <span style={{ fontWeight: "normal" }}>{song.isrc}</span>
                    </h4>
                    <h4>Artist(s): {song.songArtist}</h4>
                    {song.artists &&
                      song.artists[0] &&
                      song.artists.map((art) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <h3 key={art.artistName}>{art.artistName} </h3>
                          <Popup
                            trigger={
                              <button
                                type="button"
                                style={{
                                  fontSize: "15px",
                                  background: "none",
                                  border: "solid 1px",
                                  margin: "5px 5px 5px 5px",
                                  cursor: "pointer",
                                  borderRadius: "3px",
                                }}
                              >
                                Show Artist Details
                                <FaExpandAlt />
                              </button>
                            }
                            {...{ contentStyle: { width: "500px" } }}
                            position="bottom center"
                            //className="my-popup"
                          >
                            <div
                              className=""
                              style={{
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                wordBreak: "break-all",
                                // width: "180px",
                              }}
                            >
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Artist Name:
                                </span>{" "}
                                {art.artistName}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Artist Role:
                                </span>{" "}
                                {art.role}
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  Spotify URI:
                                </span>{" "}
                                <a
                                  href={
                                    //if art.spotifyURI starts with http, then use it, other, if it starts with spotify:artist, the use https://open.spotify.com/artist/ + art.spotifyURI.split(":")[2] + ?
                                    art.spotifyURI.startsWith("http")
                                      ? art.spotifyURI
                                      : art.spotifyURI.startsWith(
                                          "spotify:artist"
                                        )
                                      ? "https://open.spotify.com/artist/" +
                                        art.spotifyURI.split(":")[2] +
                                        "?si="
                                      : "https://open.spotify.com/artist/" +
                                        art.spotifyURI +
                                        "?si="
                                  }
                                  target="_blank"
                                  style={{
                                    //make it look like a link
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {art.spotifyURI}
                                </a>
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Apple ID:
                                </span>{" "}
                                <a
                                  href={
                                    art.appleId.startsWith("http")
                                      ? art.appleId
                                      : "https://music.apple.com/us/artist/" +
                                        art.appleId
                                  }
                                  style={{
                                    //make it look like a link
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  target="_blank"
                                >
                                  {art.appleId}
                                </a>
                              </p>
                            </div>
                          </Popup>
                        </div>
                      ))}
                    <h3
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      Writers:
                    </h3>
                    {song.writers.length == 0 && (
                      <p
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        None
                      </p>
                    )}
                    {song.writers &&
                      song.writers[0] &&
                      song.writers.map((writer) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "15px",
                            }}
                            key={writer.writerName}
                          >
                            {writer.writerName}{" "}
                          </p>
                        </div>
                      ))}
                    <h3
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      Producers:
                    </h3>
                    {song.producers.length == 0 && (
                      <p
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        None
                      </p>
                    )}
                    {song.producers &&
                      song.producers[0] &&
                      song.producers.map((producer) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <p style={{ fontSize: "15px" }}>
                            {producer.producerName}{" "}
                          </p>
                        </div>
                      ))}
                    <h4>
                      Start Time for Tiktok:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {song.previewStartTime}
                      </span>
                    </h4>
                    <audio controls src={song.audioUrl}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                ))}
              </div>
            </>
          )}
          <br />
          {user && user.role == "admin" && (
            <button onClick={handleDownload}>Download Files</button>
          )}
          <h4>Live Date: {goal.liveDate.substr(0, 10)}</h4>
        </div>

        <div style={{}}>
          <h3
            style={{
              color:
                currentStatus == "pending approval by team"
                  ? "#b5a000"
                  : currentStatus == "Album rejected."
                  ? "red"
                  : currentStatus == "Album approved!"
                  ? "green"
                  : "black",
            }}
          >
            Status: {currentStatus}
          </h3>
          {goal.rejectionReason && goal.status == "Album rejected." && (
            <h5>
              Rejection Reason:{" "}
              <span style={{ fontWeight: "normal", paddingBottom: "50px" }}>
                {goal.rejectionReason}
              </span>
            </h5>
          )}
          {user && user.role == "admin" && (
            <section className="form">
              <h4>Update status:</h4>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album approved!");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    { status: "Album approved!" },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: status,
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: "Album approved!",
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${goal.text}: Album approved!`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  // set the status to "Album approved!"
                  setCurrentStatus("Album approved!");
                  goal.status = "Album approved!";
                }}
              >
                Approve
              </button>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album rejected.");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    {
                      status: "Album rejected.",
                      rejectionReason: rejectionReason ? rejectionReason : "",
                    },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: `Album rejected. ${
                      rejectionReason ? "Reason: " + rejectionReason : ""
                    }`,
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: `Album rejected. ${
                        rejectionReason ? "Reason: " + rejectionReason : ""
                      }`,
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${
                        goal.text
                      }: Album rejected. ${
                        rejectionReason ? "Reason: " + rejectionReason : ""
                      }`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  // window.location.reload(false);
                  // set the status to "Album rejected."
                  setCurrentStatus("Album rejected.");
                  goal.status = "Album rejected.";
                  setRejectionReason("");
                }}
              >
                Reject
              </button>
              {/* Add rejection reason input */}
              <div className="form-group">
                <label htmlFor="text">Rejection reason:</label>
                <input
                  type="text"
                  value={rejectionReason}
                  onChange={(e) => {
                    setRejectionReason(e.target.value);
                  }}
                ></input>
              </div>
              <form onSubmit={onSubmit}>
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="text">Send custom status message:</label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-submit"
                      type="submit"
                      style={{ marginTop: "20px", width: "50%" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </section>
          )}
        </div>
      </div>

      {user && user.email == "admin@thesystemrecords.com" && (
        <button
          onClick={() => {
            dispatch(deleteGoal(goal._id));
          }}
          className="close"
        >
          X
        </button>
      )}
    </div>
  );
}

export default GoalItem;
